import { PersonalInfoComponent } from "./PersonalInfoComponent";
import { WorkExperienceComponent } from "./WorkExperienceComponent";
import {
  OmniStyleComponent,
  OmniTileComponent,
  OmniToolbarComponent,
} from "./omni-components";
import "../styles/App.css";
import { useState } from "react";
import ResumePreviewComponent from "./ResumePreviewComponent";
import annalectLogo from "../images/Annalect.svg";
import { EducationExperienceComponent } from "./EducationExperienceComponent";
import { SkillComponent } from "./SkillComponent";
import { CertificationComponent } from "./CertificationComponent";
import { ToolsComponent } from "./ToolsComponent";

export const App = () => {
  // all the tabs goes here...
  const [tabs, setTabs] = useState([
    {
      id: 1,
      name: "Personal details",
    },
    {
      id: 2,
      name: "Professional Experience",
    },
    {
      id: 3,
      name: "Educational Details",
    },
    {
      id: 4,
      name: "Skills",
    },
    {
      id: 5,
      name: "Tools",
    },
    {
      id: 6,
      name: "Certifications",
    },
    {
      id: 7,
      name: "Preview",
    },
  ]);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [personalInfo, setPersonalInfo] = useState(null);
  const [workExperience, setWorkExperience] = useState(null);
  const [eductaionExperience, setEducationExperience] = useState(null);
  const [skill, setSkill] = useState(null);
  const [tools, setTools] = useState(null);
  const [certification, setCertification] = useState(null);
  const getPersonalInfo = (data) => {
    setPersonalInfo(data);
    window.sessionStorage.setItem("personalInfo", JSON.stringify(personalInfo));
  };
  const getWorkExp = (data) => {
    // console.log(data);
    setWorkExperience(data);

    // console.log(workExperience);
    window.sessionStorage.setItem("workExp", JSON.stringify(workExperience));
  };
  const getEducationExp = (data) => {
    setEducationExperience(data);
    window.sessionStorage.setItem(
      "educationExp",
      JSON.stringify(eductaionExperience)
    );
  };
  const getSkill = (data) => {
    setSkill(data);
    window.sessionStorage.setItem("skill", JSON.stringify(skill));
  };

  const getTools = (data) => {
    setTools(data);
    window.sessionStorage.setItem("tools", JSON.stringify(tools));
  };

  const getCertification = (data) => {
    setCertification(data);
    window.sessionStorage.setItem(
      "certification",
      JSON.stringify(certification)
    );
  };

  const handleCurrentTab = (ct) => {
    const getTabDetail = tabs.find((tab) => tab.name === ct.name);
    setActiveTab(getTabDetail);
  };

  return (
    <>
      <OmniStyleComponent>
        <div className="columns pl-6 pt-5 pr-6 is-flex is-flex-direction-column">
          <div className="columns is-full p-0 is-flex is-align-items-center is-justify-content-space-between mb-0">
            <div className="column is-flex is-align-items-center">
              <img
                src={annalectLogo}
                alt="annalect"
                style={{ width: "5em", height: "4em" }}
              />
              <p
                className="has-text-weight-bold is-uppercase is-size-3"
                style={{ letterSpacing: "2px" }}
              >
                <span className="color-purple">Res</span>
                <span className="color-tintblue">ume </span>
                <span className="color-green">Buil</span>
                <span className="color-skyblue">der</span>
              </p>
            </div>
          </div>
          <div className="column is-full">
            <OmniTileComponent heading="">
              <OmniToolbarComponent slot="header" style={{ width: "94vw" }}>
                {tabs.map((tab) => (
                  <div className="pt-2 pb-2 pr-2" key={tab.name}>
                    <button
                      slot="start"
                      className={`button has-text-weight-normal ${
                        activeTab.name === tab.name
                          ? "is-link has-text-weight-bold has-text-white"
                          : "is-white"
                      }`}
                      onClick={() => handleCurrentTab(tab)}
                    >
                      <p
                        className={`has-text-weight-normal ${
                          activeTab.name === tab.name
                            ? "has-text-weight-bold"
                            : "label"
                        }`}
                      >
                        {tab.name}
                      </p>
                    </button>
                  </div>
                ))}
                <div slot="end" className="is-flex" style={{ gap: "10px" }}>
                  {/* <button
                    slot="end"
                    className={`button has-text-weight-normal is-link ${
                      activeTab.name === "Preview" ? "show" : "hide"
                    }`}
                    onClick={() => {
                      window.sessionStorage.removeItem("skill");
                      // setPersonalInfo(null);
                      // setWorkExperience(null);
                      // setEducationExperience(null);
                      // getSkill(null);
                      setSkill(null);
                      // setCertification(null);
                    }}
                  >
                    Clear form
                  </button> */}
                  {/* <button
                    slot="end"
                    className={`button has-text-weight-normal is-link ${
                      activeTab.name === "Preview" ? "show" : "hide"
                    }`}
                    onClick={handleGeneratePdf}
                  >
                    Generate PDF
                  </button> */}
                </div>
              </OmniToolbarComponent>
              <div>
                {activeTab.name === "Personal details" ? (
                  <PersonalInfoComponent getPersonalInfo={getPersonalInfo} />
                ) : null}
                {activeTab.name === "Professional Experience" ? (
                  <WorkExperienceComponent getWorkExp={getWorkExp} />
                ) : null}
                {activeTab.name === "Educational Details" ? (
                  <EducationExperienceComponent
                    getEducationExp={getEducationExp}
                  />
                ) : null}
                {activeTab.name === "Skills" ? (
                  <SkillComponent getSkill={getSkill} />
                ) : null}
                {activeTab.name === "Tools" ? (
                  <ToolsComponent getTools={getTools} />
                ) : null}
                {activeTab.name === "Certifications" ? (
                  <CertificationComponent getCertification={getCertification} />
                ) : null}
                {activeTab.name === "Preview" ? (
                  <ResumePreviewComponent />
                ) : null}
              </div>
            </OmniTileComponent>
          </div>
          {/* <div className="column is-half">
            <OmniTileComponent heading="Preview">
              <OmniToolbarComponent></OmniToolbarComponent>
              <ResumePreviewComponent />
            </OmniTileComponent>
          </div> */}
        </div>
      </OmniStyleComponent>
    </>
  );
};
