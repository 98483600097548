export let env;
export let Constants;

export function setEnv(environment) {
  env = environment;
  let host_prefix = env === "prod" ? "" : env;
  Constants = {
    AuthUrl: `https://tsmaa7zvv5.execute-api.us-east-1.amazonaws.com/${env}/auth`,
    CampaignDataUrl: `https://${host_prefix}mediaops-api.annalect.com/${env}/campaigns`,
    LoginRedirectUrl: `https://${host_prefix}omni.annalect.com/extsso?resourcekey=an_mediaops&redirecturl=`,
    MediaOpsDevUrl: `https://${host_prefix}mediaops.annalect.com/`,
    // MediaOpsDevUrl: `http://localhost:3000/`,
    GetOmniProfileUrl: `https://${host_prefix}mediaops-api.annalect.com/${env}/profile`,
    taxonomyUrl: `https://${host_prefix}mediaops-api.annalect.com/${env}/taxonomy`,
    ExportPlacementsUrl: `https://${host_prefix}mediaops-api.annalect.com/${env}/download/placement-templates/`,
    ClientDataUrl: `https://${host_prefix}mediaops-api.annalect.com/${env}/clients`,
    MediaOceanStatus: "Ready to process",
  };
}

export const ServedByOptions = [
  { label: "3rd party", value: "3rd party" },
  { label: "Site", value: "Site" },
  { label: "Other", value: "Other" },
];

export const BuyType = [{ label: "Standalone", value: "Standalone" }];

export const BuyCategoryOrMerketplace = [
  { label: "Standard", value: "Standard" },
  { label: "Pre-roll", value: "Pre-roll" },
];

export const Marketplace = [
  { label: "Display", value: "Display" },
  { label: "Video", value: "Video" },
];

export const DimensionsPosition = [
  { label: "Medium Rectangle", value: "Medium Rectangle" },
  { label: "Wide Skyscrapper", value: "Wide Skyscrapper" },
  { label: "Leaderboard", value: "Leaderboard" },
  { label: "In-Banner Video", value: "In-Banner Video" },
];

export const UnitDimentions = [
  { label: "120 x 240 - Vertical Banner", value: "120 x 240" },
  { label: "250 x 250 - Square Pop-Up", value: "250 x 250" },
  { label: "468 x 60 - Full Banner", value: "468 x 60" },
  { label: "120 x 60 - Button 2", value: "120 x 60" },
  { label: "300 x 100 - 3:1 Rectangle", value: "300 x 100" },
  { label: "720 x 300 - Pop-Under", value: "720 x 300" },
  { label: "120 x 600 - Skyscraper", value: "120 x 600" },
  { label: "300 x 1050 - Portrait", value: "300 x 1050" },
  { label: "728 x 90 - Leaderboard", value: "728 x 90" },
  { label: "120 x 90 - Button 1", value: "120 x 90" },
  { label: "300 x 250 - Medium Rectangle", value: "300 x 250" },
  { label: "970 x 250 - Billboard", value: "970 x 250" },
  { label: "125 x 125 - Square Button", value: "125 x 125" },
  { label: "300 x 250 - Sidekick", value: "300 x 250" },
  { label: "970 x 250 - Sidekick", value: "970 x 250" },
  { label: "160 x 600 - Wide Skyscraper", value: "160 x 600" },
  { label: "300 x 600 - Filmstrip", value: "300 x 600" },
  { label: "970 x 90 - Pushdown", value: "970 x 90" },
  { label: "180 x 150 - Rectangle", value: "180 x 150" },
  { label: "300 x 600 - Half Page Ad", value: "300 x 600" },
  { label: "970 x 90 - Slider", value: "970 x 90" },
  { label: "234 x 60 - Half Banner", value: "234 x 60" },
  { label: "300 x 600 - Sidekick", value: "300 x 600" },
  { label: "N/A - N/A", value: "N/A x N/A" },
  { label: "240 x 400 - Vertical Rectangle", value: "240 x 400" },
  { label: "336 x 280 - Large Rectangle", value: "336 x 280" },
  { label: "88 x 31 - Micro Bar", value: "88 x 31" },
];
export const CostMethods = [
  { label: "CPM", value: "CPM" },
  { label: "CPC", value: "CPC" },
  { label: "Flat", value: "Flat" },
];

export const Providers = [
  { label: "faceb0ok", value: "FB" },
  { label: "twitter", value: "TT" },
  { label: "YouTube", value: "YouTube" },
];

export const UnitType = [
  { label: "Impressions", value: "Impressions" },
  { label: "Clicks", value: "Clicks" },
];


export const subFunctions = [
  {
      id:1,
      label: "Biddable",
      value: "Biddable"
  },
  {
      id:2,
      label: "Ad Operations",
      value: "Ad Operations"
  },
  {
      id:3,
      label: "Omni Engineering",
      value: "Omni Engineering"
  },
  {
      id:4,
      label: "Finance Operations",
      value: "Finance Operations"
  },
  {
      id:5,
      label: "Design Studio",
      value: "Design Studio"
  },
  {
      id:6,
      label: "DevOps",
      value: "DevOps"
  },
  {
      id:7,
      label: "QA",
      value: "QA"
  },
  {
      id:8,
      label: "Delivery",
      value: "Delivery"
  },
  {
      id:9,
      label: "Reporting & Insights",
      value: "Reporting & Insights"
  },
  {
      id:10,
      label: "Creative Studio",
      value: "Creative Studio"
  },
  {
      id:11,
      label: "Media Operations",
      value: "Media Operations"
  },
  {
      id:12,
      label: "SME Services",
      value: "SME Services"
  },
  {
      id:13,
      label: "Media Investment",
      value: "Media Investment"
  },  {
     id:14,
      label: "HR Operations",
      value: "HR Operations"
  },
  {
      id:15,
      label: "Advanced Analytics",
      value: "Advanced Analytics"
  },
  {
      id:16,
      label: "Studio Management",
      value: "Studio Management"
  },
  {
      id:17,
      label: "Governance",
      value: "Governance"
  },
  {
      id:18,
      label: "OMC Engineering",
      value: "OMC Engineering"
  },
  {
      id:19,
      label: "Specialized Services",
      value: "Specialized Services"
  },
  {
      id:20,
      label: "Content Studio",
      value: "Content Studio"
  },
  {
      id:21,
      label: "Survey programming",
      value: "Survey programming"
  },
  {
      id:22,
      label: "Project Management",
      value: "Project Management"
  },
  {
      id:23,
      label: "Client Services",
      value: "Client Services"
  },
  {
      id:24,
      label: "Healthcare Delivery",
      value: "Healthcare Delivery"
  },
  {
      id:25,
      label: "IT Operations",
      value: "IT Operations"
  },
  {
      id:26,
      label: "Adzu Engineering",
      value: "Adzu Engineering"
  },
  {
      id:27,
      label: "Management",
      value: "Management"
  },
  {
      id:28,
      label: "Media Planning",
      value: "Media Planning"
  },
  {
      id:29,
      label: "Product Enablement",
      value: "Product Enablement"
  },
  {
      id:30,
      label: "Media Monitoring",
      value: "Media Monitoring"
  },
  {
      id:31,
      label: "Business Reporting",
      value: "Business Reporting"
  },
  {
      id:32,
      label: "Executive Assistant",
      value: "Executive Assistant"
  },
  {
      id:33,
      label: "Paralegal Services",
      value: "Paralegal Services"
  },
  {
      id:34,
      label: "Video Production",
      value: "Video Production"
  },  {
     id:35,
      label: "Technology",
      value: "Technology"
  },
  {
      id:36,
      label: "Customer Operations",
      value: "Customer Operations"
  },
  {
      id:37,
      label: "Product",
      value: "Product"
  },
  {
      id:38,
      label: "Enterprise Business Systems",
      value: "Enterprise Business Systems"
  },
  {
      id:39,
      label: "Governance, Risk, and Compliance (GRC)",
      value: "Governance, Risk, and Compliance (GRC)"
  },
  {
      id:40,
      label: "IT Analytics and Reporting",
      value: "IT Analytics and Reporting"
  },
  {
      id:41,
      label: "Endpoint Management",
      value: "Endpoint Management"
  },
  {
      id:42,
      label: "Modern Workplace Services",
      value: "Modern Workplace Services"
  },
  {
      id:43,
      label: "ServiceNow Development",
      value: "ServiceNow Development"
  },
  {
      id:44,
      label: "IT Project Management",
      value: "IT Project Management"
  },
  {
      id:45,
      label: "Intelligent Automation (IA)",
      value: "Intelligent Automation (IA)"
  },
  {
      id:46,
      label: "Networking Services",
      value: "Networking Services"
  },
  {
      id:47,
      label: "Cloud and OS Services",
      value: "Cloud and OS Services"
  },
  {
      id:48,
      label: "Corporate Application and Database Services",
      value: "Corporate Application and Database Services"
  },
  {
      id:49,
      label: "Security Operations and Architecture",
      value: "Security Operations and Architecture"
  },
  {
      id:50,
      label: "Enterprise Architecture Services",
      value: "Enterprise Architecture Services"
  },
  {
      id:51,
      label: "Accounting",
      value: "Accounting"
  },
  {
      id:52,
      label: "Research Data Operations",
      value: "Research Data Operations"
  },
  {
      id:53,
      label: "Research Project Management",
      value: "Research Project Management"
  },
  {
      id:54,
      label: "Research Operations",
      value: "Research Operations"
  },
  {
      id:55,
      label: "Data analysis , reporting, management",
      value: "Data analysis , reporting, management"
  },
  {
      id:56,
      label: "Print Production",
      value: "Print Production"
  },
  {
      id:57,
      label: "Product Management",
      value: "Product Management"
  },
  {
      id:58,
      label: "Creative Studio",
      value: "Creative Studio"
  },
  {
      id:59,
      label: "Reporting & Insights",
      value: "Reporting & Insights"
  },
  {
      id:60,
      label: "Talent Synergy & Solutions",
      value: "Talent Synergy & Solutions"
  },
  {
      id:61,
      label: "Account Management",
      value: "Account Management"
  },
  {
      id:62,
      label: "Creative Services",
      value: "Creative Services"
  },
  {
      id:63,
      label: "Client Success",
      value: "Client Success"
  },
  {
      id:64,
      label: "PMO",
      value: "PMO"
  }
]