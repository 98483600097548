import { useEffect, useState, memo } from "react";
import "../styles/App.css";
import ogdlogo from "../images/ogsLogo.png";
import { Margin, Resolution, usePDF } from "react-to-pdf";
import { ModalComponent } from "./ModalComponent";
import pngtree from "../images/avatar-default.png";
const ResumePreviewComponent = () => {
  const [personalInfo, setPersonalInfo] = useState(null);
  const [workExperience, setWorkExperience] = useState(null);
  const [educationExperience, setEducationExperience] = useState(null);
  const [skill, setSkill] = useState(null);
  const [tools, setTools] = useState(null);
  const [certification, setCertification] = useState(null);
  const [loading, setLoading] = useState(false);
  const [validPersonalInfo, setValidPersonalInfo] = useState(false);
  const [modal, setModal] = useState(false);
  useEffect(() => {
    setWorkExperience(JSON.parse(window.sessionStorage.getItem("workExp")));
    setPersonalInfo(JSON.parse(window.sessionStorage.getItem("personalInfo")));
    setEducationExperience(
      JSON.parse(window.sessionStorage.getItem("educationExp"))
    );
    setSkill(JSON.parse(window.sessionStorage.getItem("skill")));
    setTools(JSON.parse(window.sessionStorage.getItem("tools")));
    setCertification(
      JSON.parse(window.sessionStorage.getItem("certification"))
    );
    return () => {
      setSkill(null);
    };
  }, []);

  const { toPDF, targetRef } = usePDF({
    filename: `${personalInfo?.fName.value}-${personalInfo?.lName.value}.pdf`,
    resolution: Resolution.HIGH,
    page: { margin: Margin.SMALL, format: "a4", orientation: "portrait" },
    overrides: {
      pdf: {
        compress: true,
      },
      canvas: {
        useCORS: true,
      },
    },
  });

  const personalInterests = [
    {
      id: 1,
      value: 'Playing',
    },
    {
      id: 2,
      value: 'Eating',
    },
    {
      id: 2,
      value: 'Reading',
    }
  ]

  const validatePersonalDetailInput = () => {
    if (
      personalInfo.fName.required &&
      !personalInfo.fName.invalid &&
      personalInfo.lName.required &&
      !personalInfo.lName.invalid &&
      personalInfo.email.required &&
      !personalInfo.email.invalid &&
      personalInfo.designation.required &&
      !personalInfo.designation.invalid &&
      personalInfo.phone.required &&
      !personalInfo.phone.invalid &&
      personalInfo.city.required &&
      !personalInfo.city.invalid &&
      personalInfo.domainName.required &&
      !personalInfo.domainName.invalid &&
      personalInfo.yearsOfExp.required &&
      !personalInfo.yearsOfExp.invalid &&
      personalInfo.skill1.required &&
      !personalInfo.skill1.invalid &&
      personalInfo.skill2.required &&
      !personalInfo.skill2.invalid &&
      personalInfo.skill3.required &&
      !personalInfo.skill3.invalid
    ) {
      setValidPersonalInfo(true);
      setModal(true);
      return true;
    }
    setValidPersonalInfo(false);
    setModal(false);
    return false;
  };

  const handleGeneratePDF = () => {
    validatePersonalDetailInput();
    // if (!validPersonalInfo) {
    //   return;
    // }
    setLoading(true);
    toPDF();
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const getTense = (endDate) => {
    if (new Date().getTime() > new Date(endDate).getTime()) {
      return "am";
    }
    if (new Date().getTime() < new Date(endDate).getTime()) {
      return "was";
    }
  };

  return (
    <>
      {modal ? (
        <ModalComponent message="Required fields are not filled in Personal Information. Please update it." />
      ) : (
        ""
      )}
      <div className="columns">
        <div
          className="column is-flex is-justify-content-flex-end"
          style={{ gap: "15px" }}
        >
          <button
            slot="end"
            className={`button has-text-weight-normal ${
              loading ? "skeleton is-white is-shadowless" : "is-link"
            }
        `}
            onClick={() => {
              window.sessionStorage.removeItem("skill");
              setSkill(null);
              window.sessionStorage.removeItem("tools");
              setTools(null);
              window.sessionStorage.removeItem("certification");
              setCertification(null);
              window.sessionStorage.removeItem("personalInfo");
              setPersonalInfo(null);
              window.sessionStorage.removeItem("workExp");
              setWorkExperience(null);
              window.sessionStorage.removeItem("educationExp");
              setEducationExperience(null);
            }}
          >
            Clear Form
          </button>
          <button
            slot="end"
            className={`button has-text-weight-normal ${
              loading ? "skeleton is-white is-shadowless" : "is-link"
            }
        `}
            onClick={toPDF}
          >
            Generate PDF
          </button>
        </div>
      </div>

      <div
        ref={targetRef}
        className="is-flex is-flex-direction-column is-justify-content-space-between pl-6"
        style={{ gap: "15px", pageBreakInside: "avoid", borderLeft: "15px solid blue" }}
      >
        <div className="columns">
          <div className="column is-flex is-justify-content-flex-end">
            <img
              src={ogdlogo}
              alt="omnicom global delivery"
              style={{ width: "24em" }}
            />
          </div>
        </div>
        <div className="columns is-flex is-flex-direction-row">
          <div className="column is-one-quarter">
          <div className="">
            <img style={{ width: "15em", height: "15em" }}
            src={window?.sessionStorage.getItem('selfie') || pngtree}
            alt="selfie" />
            <h1 style={{ fontSize: "32px", fontWeight: "500", color: "black" }}>
              {personalInfo?.fName?.value} {personalInfo?.lName?.value}
            </h1>
            {console.log('personalInfo?.subFunctionName', personalInfo?.subFunctionName)}
            <h1 className="blueHeader is-size-2">
              {personalInfo?.designation?.value}{" "}
              {personalInfo?.subFunctionName?.value
                ? `- ${personalInfo?.subFunctionName?.value?.trim()}`
                : ""}
            </h1>{" "}
          </div>
            <div
              className="is-flex is-flex-direction-column"
              style={{ gap: "1rem" }}
            >
              <div
                style={{ listStyleType: "disc"}}
                  className="pt-4 pb-4 pr-5"
                  dangerouslySetInnerHTML={{ __html: personalInfo?.aboutMe?.value }}
              />
              <div>
                <h1 className="has-text-weight-bold is-size-1 color-blue">Contacts</h1>
                {/* <h1 className="has-text-weight-bold is-size-5">
                  {personalInfo?.email?.value || "name@annalect.com"}
                </h1> */}
                <ul className="is-size-4" style={{ listStyle: "none" }}>
                    <li><span style={{ fontWeight: "600" }}>Email: </span>{personalInfo?.email?.value || "name@annalect.com"}</li>
                    <li><span style={{ fontWeight: "600" }}>LinkedIn: </span>{personalInfo?.portfolioLink?.value || ""}</li>
                    <li><span style={{ fontWeight: "600" }}>Phone: </span>{personalInfo?.phone?.value || "name@annalect.com"}</li>
                </ul>
              </div>
              <div>
                <h1 className="has-text-weight-bold is-size-1 color-blue">Skills</h1>
                {/* <h1 className="has-text-weight-bold is-size-5">
                  Programming languages & Skills
                </h1> */}
                <ul className="is-size-4" style={{ listStyle: "none" }}>
                  {skill?.map((sk) => (
                    <li>{sk.skill.value}</li>
                  ))}
                </ul>
              </div>
              <div>
                <h1 className="has-text-weight-bold is-size-1 color-blue">Tools</h1>
                  {tools?.map((tl) => tl.tool.value).join(', ')}
              </div>
              <div
                className="is-flex is-flex-direction-column"
                style={{ gap: "1.0rem" }}
              >
                <h1 className="has-text-weight-bold is-size-1 color-blue">Education</h1>
                {educationExperience?.map((edu) => (
                  <div>
                    <h1 className="has-text-weight-normal is-size-4 blueHeader">
                      {edu.schoolName.value}{" "}
                      {/* <strong className="blueHeader">({edu?.universityName?.value})</strong> */}
                    </h1>
                    <h1 className="has-text-weight-bold is-size-4 color-blue">
                      {edu.degree.value}
                    </h1>
                    <h1 className="has-text-weight-normal is-size-4">
                      {new Date(edu.startDate.value).getFullYear()} -{" "}
                      {new Date(edu.endDate.value).getFullYear()}
                    </h1>
                  </div>
                ))}
              </div>
              {
                certification?.length > 0 && 
                <div
                  className="is-flex is-flex-direction-column"
                  style={{ gap: "1.0rem" }}
                >
                  <h1 className="has-text-weight-bold is-size-1 color-blue">
                    Certifications
                  </h1>
                  {certification?.map((edu) => (
                    <div>
                      <h1 className="has-text-weight-bold is-size-4 blueHeader">
                        {edu.certificationName.value}
                      </h1>
                      <h1 className="has-text-weight-normal is-size-4">
                        {edu.issuingInst.value}, {' '}
                        <span className="has-text-weight-normal is-size-4">
                          {new Date(edu.issueDate.value).getMonth()}/
                          {new Date(edu.issueDate.value).getDate()}/
                          {new Date(edu.issueDate.value).getFullYear()}
                        </span>
                      </h1>
                    </div>
                  ))}
                </div>
              }

              {/* <div
                className="is-flex is-flex-direction-column"
              >
                <h1 className="has-text-weight-bold is-size-1 color-blue">
                  Personal Interests
                </h1>
                  <div className="">
                      <p>{personalInterests.map((i) => i.value).join(', ')}</p>
                  </div>
              </div> */}
            </div>
          </div>
          {/* <h1 className="separator pr-2"></h1> */}
          <div
            className="column is-three-quarters is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-flex-start"
          >
            <h1 className="has-text-weight-bold is-size-1 color-blue pl-3">Experience</h1>
            {workExperience?.map((wexp, index) => (
              <div key={wexp?.id} className="columns is-flex col-gap border-bottom pb-1 column is-full">
                <div className="column is-one-quarter">
                    <h1 className="has-text-weight-bold is-size-3 blueHeader">
                      {wexp.companyName.value}
                    </h1>
                    <p>
                      {new Date(wexp.startDate.value).toLocaleString("default", {
                        month: "short",
                      })}{" "}
                      {new Date(wexp.startDate.value).getFullYear()} -{" "}
                      {wexp.currentlyEmployed.value
                        ? "Present"
                        : `${new Date(wexp.endDate.value)?.toLocaleString(
                          "default",
                          {
                            month: "short",
                          }
                        )} ${new Date(wexp.endDate.value).getFullYear()}`}
                    </p>
                    <p className="is-size-4">
                      {index === 0 ? "Full time" : wexp?.functionName?.value
                        ? `${wexp?.functionName?.value}`
                        : ""}
                    </p>
                    <p className="is-size-4">
                      {wexp?.city?.value
                        ? `${wexp?.city?.value}`
                        : ""}{", "}
                        {wexp?.state?.value
                        ? `${wexp?.state?.value}`
                        : ""}{", "}
                        {wexp?.country?.value
                        ? `${wexp?.country?.value}`
                        : ""}
                    </p>
                </div>
                <div className="column is-three-quarters is-flex is-flex-direction-column is-justify-content-center">
                    <h1 className="has-text-weight-bold is-size-5 color-blue">
                      {wexp.designation.value}{" "}
                    </h1>
                    <h1 className="has-text-weight-normal is-size-4">
                      <div
                      style={{ listStyleType: "disc"}}
                        className="pt-4 pb-4 pr-5 innerHtml"
                        dangerouslySetInnerHTML={{ __html: wexp.workSummary.value }}
                        />
                    </h1>
                  </div>
              </div>
            ))}
            {/* <div
              className="column is-full is-flex is-flex-direction-column is-justify-content-flex-start is-align-items-flex-start pl-0"
              style={{ gap: "2rem" }}
            >
              <h1 className="has-text-weight-bold is-size-1">Projects</h1>
              {workExperience?.map((wexp) => (
                <>
                  {wexp.projects.map((proj) => (
                    <div className="is-flex is-flex-direction-column col-gap border-bottom pb-4 column is-full">
                      <h1 className="has-text-weight-normal is-size-5">
                        <strong>{proj.projectName.value} </strong> (
                        {new Date(proj.projStartDate.value).toLocaleString(
                          "default",
                          {
                            month: "short",
                          }
                        )}{" "}
                        {new Date(proj.projStartDate.value).getFullYear()} -{" "}
                        {new Date(proj.projEndDate.value).toLocaleString(
                          "default",
                          {
                            month: "short",
                          }
                        )}{" "}
                        {new Date(proj.projEndDate.value).getFullYear()})
                      </h1>
                      <strong>
                        {proj?.projDesignation.value}{" "}
                        {proj?.projFunctionName?.value
                          ? `- ${proj?.projFunctionName?.value}`
                          : ""}
                      </strong>
                      <h1 className="has-text-weight-normal is-size-5">
                        <strong>Job description:</strong> Describe your project
                        in few words and elaborate your roles in what you have
                        done in projects.
                      </h1>
                      <h1 className="has-text-weight-normal is-size-5">
                        <strong>Roles and responsibilities: </strong>
                        <div
                          className="pt-4 pb-4 pl-5 pr-5"
                          dangerouslySetInnerHTML={{
                            __html: proj.projSummary.value,
                          }}
                        />
                      </h1>
                    </div>
                  ))}
                </>
              ))}
            </div> */}
          </div>
        </div>
        {/* {personalInfo?.portfolioLink?.value?.length > 0 ? (
          <div className="columns">
            <div className="column is-flex is-justify-content-center">
              <strong className="pr-2">
                Portfolio Link [Only for Creative Services]:{" "}
              </strong>
              {personalInfo?.portfolioLink?.value}
            </div>
          </div>
        ) : (
          ""
        )} */}
      </div>
    </>
  );
};

export default memo(ResumePreviewComponent);
