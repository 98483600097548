import { useEffect, useState } from "react";
import "../styles/BasicInfo.css";
import { OmniTooltipComponent, OmniIconComponent } from "./omni-components";
export const CertificationComponent = ({ getCertification }) => {
  const [experiences, setExperiences] = useState([
    {
      certificationName: { id: "certificationName", value: "" },
      issuingInst: { id: "issuingInst", value: "" },
      issueDate: { id: "issueDate", value: "" },
      show: true,
    },
  ]);

  useEffect(() => {
    if (window.sessionStorage.getItem("certification")) {
      setExperiences(
        JSON.parse(window.sessionStorage.getItem("certification"))
      );
    }
  }, []);

  const handleWorkExpInputChange = (e, key, index) => {
    const { name, value } = e.target;
    const nextCounters = experiences.map((c, i) => {
      if (i === index) {
        c[`${key}`]["value"] = value;
      }
      return c;
    });
    setExperiences(nextCounters);
    getCertification(experiences);
  };

  const toggleAccordian = (e, index) => {
    const isShow = experiences[index]?.show ? false : true;
    let newArr = [...experiences];
    newArr[index].show = isShow;
    setExperiences(newArr);
  };

  const handleWorkExperience = () => {
    const newArr = [...experiences];
    newArr.push({
      certificationName: { id: "certificationName", value: "" },
      issuingInst: { id: "issuingInst", value: "" },
      issueDate: { id: "issueDate", value: "" },
      show: true,
    });
    setExperiences(newArr);
  };

  const deleteWorkExperience = (index) => {
    setExperiences([
      ...experiences.slice(0, index),
      ...experiences.slice(index + 1, experiences.length),
    ]);
  };

  return (
    <div className="is-flex is-flex-direction-column">
      {experiences &&
        experiences.map((experience, index) => (
          <div
            id={`panel-${index}`}
            key={`panel-${index}`}
            className={`panel p-2 columns mt-4 ml-0 mr-0 mb-0 p-0 is-flex is-flex-direction-column ${
              experience.show ? "panel-expand" : "panel-collapse"
            }`}
          >
            <div className="is-flex is-align-items-center is-justify-content-space-between">
              <div className="pl-4 label">Certification {index + 1}</div>
              <div className="is-flex is-align-items-center is-justify-content-space-between">
                <OmniTooltipComponent>
                  <button
                    slot="invoker"
                    className="button no-border-background is-shadowless is-text"
                    onClick={() => {
                      deleteWorkExperience(index);
                    }}
                  >
                    <OmniIconComponent
                      id={`ic-delete-${index}`}
                      className="is-size-4 is-clickable"
                      icon-id="omni:interactive:delete"
                    ></OmniIconComponent>
                  </button>
                </OmniTooltipComponent>
                <OmniTooltipComponent>
                  <button
                    slot="invoker"
                    className="button no-border-background is-shadowless is-text"
                    onClick={(e) => toggleAccordian(e, index)}
                  >
                    <OmniIconComponent
                      id={`ic-panel-${index}`}
                      className="is-size-2 is-clickable"
                      icon-id={`omni:interactive:${
                        experience.show ? "down" : "up"
                      }`}
                    ></OmniIconComponent>
                  </button>
                </OmniTooltipComponent>
              </div>
            </div>
            <div className="column pl-4 pb-5 pt-5">
              <div
                id={`panelContainer-${index}`}
                className="is-flex is-flex-direction-column pt-0 panelContainer"
                style={{ gap: "15px" }}
              >
                <div className="columns">
                  <div
                    className="column column-gap is-full"
                    key={experience.certificationName.id}
                  >
                    <p className="mb-0 ml-2 label is-size-5">
                      * Certification name
                    </p>
                    <input
                      type="text"
                      id={experience.certificationName.id}
                      className="input"
                      name={experience.certificationName.id}
                      placeholder="Enter certification name"
                      value={experience.certificationName.value || ""}
                      onChange={(e) =>
                        handleWorkExpInputChange(
                          e,
                          experience.certificationName.id,
                          index
                        )
                      }
                    />
                  </div>
                </div>
                <div className="columns">
                  <div
                    className="column column-gap is-half"
                    key={experience.issuingInst.id}
                  >
                    <p className="mb-0 ml-2 label is-size-5">
                      * Issuing Institution
                    </p>
                    <input
                      type="text"
                      id={experience.issuingInst.id}
                      className="input"
                      name={experience.issuingInst.id}
                      placeholder="Enter institution name"
                      value={experience.issuingInst.value || ""}
                      onChange={(e) =>
                        handleWorkExpInputChange(
                          e,
                          experience.issuingInst.id,
                          index
                        )
                      }
                    />
                  </div>
                  <div
                    className="column column-gap is-half"
                    key={experience.issueDate.id}
                  >
                    <p className="mb-0 ml-2 label is-size-5">* Issue Date</p>
                    <input
                      type="date"
                      id={experience.issueDate.id}
                      className="input"
                      name={experience.issueDate.id}
                      placeholder="Enter institution name"
                      value={experience.issueDate.value || ""}
                      onChange={(e) =>
                        handleWorkExpInputChange(
                          e,
                          experience.issueDate.id,
                          index
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

      <div className="columns is-flex is-justify-content-flex-start pr-3 ml-0 mt-6">
        <OmniTooltipComponent>
          <button
            id="btnCreateReport"
            slot="invoker"
            className="is-flex button is-outlined is-flex-shrink-1 mr-3 is-link"
            onClick={handleWorkExperience}
          >
            <OmniIconComponent
              id="addIcon"
              class="is-size-2 is-clickable is-normal mr-2"
              icon-id="omni:interactive:add"
            ></OmniIconComponent>
            Add more certfication
          </button>
          {/* <div slot="content">
            <p>Add work experience</p>
          </div> */}
        </OmniTooltipComponent>
      </div>
      <div className="columns is-flex is-justify-content-flex-start pr-3 mt-4">
        <p className="mb-0 ml-2 label is-size-5">* Required fields</p>
      </div>
    </div>
  );
};
