import { useEffect, useRef, useState } from "react";
import "../styles/BasicInfo.css";
import "../styles/App.css";
import {
  OmniTooltipComponent,
  OmniIconComponent,
  // OmniCheckboxComponent,
} from "./omni-components";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
export const WorkExperienceComponent = ({ getWorkExp }) => {
  const endDateRef = useRef(null);
  // const employedRef = useRef(null);
  // const fresherRef = useRef(null);
  const [checkDates, setCheckDates] = useState(false);
  const [experiences, setExperiences] = useState([
    {
      designation: {
        id: "designation",
        value: "",
        invalid: false,
        disable: false,
      },
      functionName: {
        id: "functionName",
        value: "",
        invalid: false,
        disable: false,
      },
      companyName: {
        id: "companyName",
        value: "",
        invalid: false,
        disable: false,
      },
      startDate: { id: "startDate", value: "", invalid: false, disable: false },
      endDate: { id: "endDate", value: "", invalid: false, disable: false },
      currentlyEmployed: {
        id: "currentlyEmployed",
        value: false,
        disable: false,
      },
      city: {
        id: "city",
        value: "",
        invalid: false,
        disable: false
      },
      state: {
        id: "state",
        value: "",
        invalid: false,
        disable: false
      },
      country: {
        id: "country",
        value: "",
        invalid: false,
        disable: false
      },
      isFresher: { id: "isFresher", value: false },
      workSummary: {
        id: "workSummary",
        value: "",
        invalid: false,
        disable: false,
      },
      task1: { id: "task1", value: "", invalid: false, disable: false },
      task2: { id: "task2", value: "", invalid: false, disable: false },
      task3: { id: "task3", value: "", invalid: false, disable: false },
      client_dept: {
        id: "client_dept",
        value: "",
        invalid: false,
        disable: false,
      },
      projects: [
        {
          projectName: {
            id: "projectName",
            value: "",
            invalid: false,
            disable: false,
          },
          projStartDate: {
            id: "projStartDate",
            value: "",
            invalid: false,
            disable: false,
          },
          projEndDate: {
            id: "projEndDate",
            value: "",
            invalid: false,
            disable: false,
          },
          projSummary: {
            id: "projSummary",
            value: "",
            invalid: false,
            disable: false,
          },
          projDesignation: {
            id: "projDesignation",
            value: "",
            invalid: false,
            disable: false,
          },
          projFunctionName: {
            id: "projFunctionName",
            value: "",
            invalid: false,
            disable: false,
          },
          showProjDetail: true,
        },
      ],
      show: true,
    },
  ]);

  useEffect(() => {
    if (window.sessionStorage.getItem("workExp")) {
      setExperiences(JSON.parse(window.sessionStorage.getItem("workExp")));
    }
  }, []);

  const handleWorkExpInputChange = (e, key, index) => {
    const { value } = e.target;
    if((key === 'startDate' || key === 'endDate') && value) {
      setCheckDates(true)
    }
    const nextCounters = experiences.length && experiences.map((c, i) => {
      if (i === index) {
        c[`${key}`]["value"] = value;
        if (value.length === 0) {
          c[`${key}`]["invalid"] = true;
        } else {
          c[`${key}`]["invalid"] = false;
        }
      }
      return c;
    });
    setExperiences(nextCounters);
    getWorkExp(experiences);
  };
  

  const checkDatesOverlap = () => {
    experiences && experiences.map((row, index) => {
      let error = {};
        const startDate = new Date(row.startDate.value);
        const endDate = new Date(row.endDate.value);
  
        if (endDate < startDate) {
          error.endDate = 'End date should not be before start date';
          experiences[index]["endDate"]["invalid"] = true
        }
  
        if (index > 0) {
          const prevEndDate = new Date(experiences[index - 1].endDate.value);
          const prevStartDate = new Date(experiences[index - 1].startDate.value);
          if (startDate >= prevEndDate) {
            error.startDate = 'Start date should be after previous end date';
            experiences[index]["startDate"]["invalid"] = true
          }
          if (endDate > prevStartDate) {
            error.endDate = 'End date should be after previous end date';
            experiences[index]["endDate"]["invalid"] = true
          }
          if(startDate > prevStartDate && startDate < prevEndDate) {
            experiences[index]["startDate"]["invalid"] = true
          }
        }
        return error;
    })
  }


 

  const handleCurrentlyEmployedChange = (e, key, index) => {
    const nextCounters = experiences.length && experiences.map((c, i) => {
      if (i === index) {
        c[`${key}`]["value"] = e.target.checked;
        if (e.target.checked) {
          c["endDate"]["value"] = "";
          c["endDate"]["disable"] = true;
        } else {
          c["endDate"]["disable"] = false;
        }
      }
      return c;
    });
    setExperiences(nextCounters);
    getWorkExp(experiences);
    if (e.target.checked) {
      // endDateRef.current.classList.add("disabled");
    } else {
      // endDateRef.current.classList.remove("disabled");
    }
  };

  const handleFresherChange = (e, key, index) => {
    const nextCounters = experiences.map((c, i) => {
      if (i === index) {
        c[`${key}`]["value"] = e.target.checked;
      }
      return c;
    });
    setExperiences(nextCounters);
    getWorkExp(experiences);
  };

  const handleWorkSummaryInutChange = (e, key, index) => {
    const nextCounters = experiences.length && experiences.map((c, i) => {
      if (i === index) {
        c[`${key}`]["value"] = e;
      }
      return c;
    });
    setExperiences(nextCounters);
    getWorkExp(experiences);
  };

  const handleProjectInputChange = (e, key, index, expIndex) => {
    const nextCounters = experiences.map((c, i) => {
      if (i === expIndex) {
        c.projects.map((p, ind) => {
          if (ind === index) {
            p[`${key}`]["value"] = e;
            if (e.length === 0) {
              p[`${key}`]["invalid"] = true;
            } else {
              p[`${key}`]["invalid"] = false;
            }
          }
          return p;
        });
      }
      return c;
    });
    setExperiences(nextCounters);
    getWorkExp(experiences);
  };

  const handleProjDetInputChange = (e, key, index, expIndex) => {
    const { value } = e.target;
    const nextCounters = experiences.map((c, i) => {
      if (i === expIndex) {
        c.projects.map((p, ind) => {
          if (ind === index) {
            p[`${key}`]["value"] = value;
            if (e.target.value.length === 0) {
              p[`${key}`]["invalid"] = true;
            } else {
              p[`${key}`]["invalid"] = false;
            }
          }
          return p;
        });
      }
      return c;
    });
    setExperiences(nextCounters);
    getWorkExp(experiences);
  };

  const toggleAccordian = (e, index) => {
    const isShow = experiences[index]?.show ? false : true;
    let newArr = [...experiences];
    newArr[index].show = isShow;
    setExperiences(newArr);
  };

  const toggleProjectAccordian = (e, projIndex, expIndex) => {
    const isShow = experiences[expIndex].projects[projIndex]?.showProjDetail
      ? false
      : true;
    let newArr = [...experiences];
    newArr[expIndex].projects[projIndex].showProjDetail = isShow;
    setExperiences(newArr);
  };

  const handleWorkExperience = () => {
    const newArr = experiences && [...experiences];
    newArr?.push({
      designation: {
        id: "designation",
        value: "",
        invalid: false,
        disable: false,
      },
      functionName: {
        id: "functionName",
        value: "",
        invalid: false,
        disable: false,
      },
      companyName: {
        id: "companyName",
        value: "",
        invalid: false,
        disable: false,
      },
      startDate: { id: "startDate", value: "", invalid: false, disable: false },
      endDate: { id: "endDate", value: "", invalid: false, disable: false },
      currentlyEmployed: {
        id: "currentlyEmployed",
        value: false,
        disable: false,
      },
      city: {
        id: "city",
        value: "",
        invalid: false,
        disable: false
      },
      state: {
        id: "state",
        value: "",
        invalid: false,
        disable: false
      },
      country: {
        id: "country",
        value: "",
        invalid: false,
        disable: false
      },
      workSummary: {
        id: "workSummary",
        value: "",
        invalid: false,
        disable: false,
      },
      task1: { id: "task1", value: "", invalid: false, disable: false },
      task2: { id: "task2", value: "", invalid: false, disable: false },
      task3: { id: "task3", value: "", invalid: false, disable: false },
      client_dept: {
        id: "client_dept",
        value: "",
        invalid: false,
        disable: false,
      },
      projects: [
        {
          projectName: {
            id: "projectName",
            value: "",
            invalid: false,
            disable: false,
          },
          projStartDate: {
            id: "projStartDate",
            value: "",
            invalid: false,
            disable: false,
          },
          projEndDate: {
            id: "projEndDate",
            value: "",
            invalid: false,
            disable: false,
          },
          projSummary: {
            id: "projSummary",
            value: "",
            invalid: false,
            disable: false,
          },
          projDesignation: {
            id: "projDesignation",
            value: "",
            invalid: false,
            disable: false,
          },
          projFunctionName: {
            id: "projFunctionName",
            value: "",
            invalid: false,
            disable: false,
          },
          showProjDetail: true,
        },
      ],
      show: true,
    });
    setExperiences(newArr);
  };

  const deleteWorkExperience = (index) => {
    setExperiences([
      ...experiences.slice(0, index),
      ...experiences.slice(index + 1, experiences.length),
    ]);
  };


  useEffect(() => {
    checkDates && checkDatesOverlap()
    setCheckDates(false);
  }, [checkDates])

  return (
    <div className="is-flex is-flex-direction-column">
      {experiences &&
        experiences?.map((experience, index) => (
          <div
            id={`panel-${index}`}
            key={`panel-${index}`}
            className={`panel p-2 columns mt-4 ml-0 mr-0 mb-0 p-0 is-flex is-flex-direction-column ${
              experience.show ? "panel-expand" : "panel-collapse"
            }`}
          >
            <div className="is-flex is-align-items-center is-justify-content-space-between">
              <div className="pl-4 label">{index === 0 ? 'OGS Experience' : `Previous Experience ${index}`}</div>
              <div className="is-flex is-align-items-center is-justify-content-space-between">
                <OmniTooltipComponent>
                  <button
                    slot="invoker"
                    className="button no-border-background is-shadowless is-text"
                    onClick={() => {
                      deleteWorkExperience(index);
                    }}
                  >
                    <OmniIconComponent
                      id={`ic-delete-${index}`}
                      className="is-size-4 is-clickable"
                      icon-id="omni:interactive:delete"
                    ></OmniIconComponent>
                  </button>
                </OmniTooltipComponent>
                <OmniTooltipComponent>
                  <button
                    slot="invoker"
                    className="button no-border-background is-shadowless is-text"
                    onClick={(e) => toggleAccordian(e, index)}
                  >
                    <OmniIconComponent
                      id={`ic-panel-${index}`}
                      className="is-size-2 is-clickable"
                      icon-id={`omni:interactive:${
                        experience.show ? "down" : "up"
                      }`}
                    ></OmniIconComponent>
                  </button>
                </OmniTooltipComponent>
              </div>
            </div>
            <div className="column pl-4 pb-5 pt-5">
              <div
                id={`panelContainer-${index}`}
                className="is-flex is-flex-direction-column pt-0 panelContainer"
                style={{ gap: "15px" }}
              >
                <div className="columns">
                  <div className="column p-0">
                    {/* <p className="mb-0 ml-2 label is-size-5 has-text-info has-text-weight-bold is-flex">
                      Note: Don't enter the experience detail, if you are a
                      fresher. You have to enter only the project details.
                    </p> */}
                    {/* <OmniIconComponent
                      className="is-flex-shrink-0 default-check current-no-check"
                      icon-id="omni:informative:check"
                      id={experience.isFresher.id}
                      ref={fresherRef}
                      click={(e) =>
                        handleFresherChange(e, experience.isFresher.id, index)
                      }
                    ></OmniIconComponent> */}
                    {/* <OmniCheckboxComponent
                      id={experience.isFresher.id}
                      name={experience.isFresher.id}
                      checked={experience.isFresher.value}
                      change={(e) =>
                        handleFresherChange(e, experience.isFresher.id, index)
                      }
                    >
                      <label> Select, if you are a fresher.</label>
                    </OmniCheckboxComponent> */}
                    {/* <p className="mb-0 ml-0 label is-size-5"> */}
                    {/* <label
                        className="is-flex is-justify-content-flex-start is-align-items-center"
                        style={{ gap: "10px", textAlign: "center" }}
                        key={experience.isFresher.id}
                      >
                        <input
                          type="checkbox"
                          id={experience.isFresher.id}
                          name={experience.isFresher.id}
                          defaultChecked={experience.isFresher.value}
                          value={experience.isFresher.value || ""}
                          onChange={(e) =>
                            handleFresherChange(
                              e,
                              experience.isFresher.id,
                              index
                            )
                          }
                        />
                        <span className="is-size-5 mt-2">
                          Select, if you are a fresher.
                        </span> */}
                    {/* <span className="mt-2">
                          <OmniTooltipComponent>
                            <button
                              slot="invoker"
                              className="button no-border-background is-shadowless is-text"
                            >
                              <OmniIconComponent
                                id={`ic-delete-${index}`}
                                className="is-size-4 is-clickable"
                                icon-id="omni:informative:info"
                              ></OmniIconComponent>
                            </button>
                            <div slot="content">
                              <p>
                                Experience detail is not required for freshers,
                                you have to enter only the project details.
                              </p>
                            </div>
                          </OmniTooltipComponent>
                        </span> */}
                    {/* </label>
                    </p> */}
                  </div>
                </div>
                <div className="columns">
                  <div
                    className="column column-gap is-half"
                    key={experience.designation.id}
                  >
                    <p className="mb-0 ml-2 label is-size-5">Designation</p>
                    <input
                      type="text"
                      id={experience.designation.id}
                      className={`input ${
                        experience.designation.invalid ? "error" : ""
                      } ${experience.designation.disable ? "disabled" : ""}`}
                      name={experience.designation.id}
                      placeholder="Enter designation"
                      value={experience.designation.value || ""}
                      onChange={(e) =>
                        handleWorkExpInputChange(
                          e,
                          experience.designation.id,
                          index
                        )
                      }
                    />
                    {experience?.designation?.value?.length === 0 &&
                    experience?.designation?.invalid ? (
                      <p className="mb-0 ml-2 label is-size-5 has-text-danger">
                        Designation is required.
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className="column column-gap is-half"
                    key={experience.functionName.id}
                  >
                    <p className="mb-0 ml-2 label is-size-5">* Full time / Part time</p>
                    {/* <input
                      type="text"
                      id={experience.functionName.id}
                      name={experience.functionName.id}
                      className={`input ${
                        experience.functionName.invalid ? "error" : ""
                      } ${experience.functionName.disable ? "disabled" : ""}`}
                      placeholder="Enter here"
                      value={experience.functionName.value || ""}
                      onChange={(e) =>
                        handleWorkExpInputChange(
                          e,
                          experience.functionName.id,
                          index
                        )
                      }
                    /> */}
                    <select name={experience.functionName.id}
                      id={experience.functionName.id}
                      value={experience.functionName.value || ""}
                      disabled={index === 0}
                      className={`input ${
                        experience.functionName.invalid ? "error" : ""
                      } ${experience.functionName.disable ? "disabled" : ""}`}
                      onChange={(e) =>
                        handleWorkExpInputChange(
                          e,
                          experience.functionName.id,
                          index
                        )
                      }
                    >
                      <option value="" disabled selected>Select your option</option>
                      <option value="Full time">Full time</option>
                      <option value="Part time">Part time</option>
                    </select>
                      {experience?.functionName?.value?.length === 0 &&
                      experience?.functionName?.invalid ? (
                        <p className="mb-0 ml-2 label is-size-5 has-text-danger">
                          Please enter your employment type.
                        </p>
                      ) : (
                        ""
                    )}
                  </div>
                </div>
                <div className="columns">
                  <div
                    className="column column-gap is-full"
                    key={experience.companyName.id}
                  >
                    <p className="mb-0 ml-2 label is-size-5">* Company name</p>
                    <input
                      type="text"
                      id={experience.companyName.id}
                      name={experience.companyName.id}
                      className={`input ${
                        experience.companyName.invalid ? "error" : ""
                      } ${experience.companyName.disable ? "disabled" : ""}`}
                      placeholder="Enter company name"
                      value={experience.companyName.value || ""}
                      onChange={(e) =>
                        handleWorkExpInputChange(
                          e,
                          experience.companyName.id,
                          index
                        )
                      }
                    />
                    {experience?.companyName?.value?.length === 0 &&
                    experience?.companyName?.invalid ? (
                      <p className="mb-0 ml-2 label is-size-5 has-text-danger">
                        Company name is required.
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="columns" key={experience.startDate.id}>
                  <div className="column column-gap is-half">
                    <p className="mb-0 ml-2 label is-size-5">
                      * Job start date
                    </p>
                    <input
                      type="month"
                      className={`input ${
                        experience.startDate.invalid ? "error" : ""
                      } ${experience.startDate.disable ? "disabled" : ""}`}
                      id={experience.startDate.id}
                      name={experience.startDate.id}
                      placeholder="Enter start date"
                      value={experience.startDate.value || ""}
                      onInput={(e) =>
                        handleWorkExpInputChange(
                          e,
                          experience.startDate.id,
                          index
                        )
                      }
                    />
                    {experience?.startDate?.value?.length === 0 &&
                    experience?.startDate?.invalid ? (
                      <p className="mb-0 ml-2 label is-size-5 has-text-danger">
                        Job start date is required.
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className="column column-gap is-half"
                    key={experience.endDate.id}
                  >
                    <p className="mb-0 ml-2 label is-size-5">* Job end date</p>
                    <input
                      ref={endDateRef}
                      id={experience.endDate.id}
                      name={experience.endDate.id}
                      type="month"
                      className={`input ${
                        experience.endDate.invalid ? "error" : ""
                      } ${experience.endDate.disable ? "disabled" : ""}`}
                      placeholder="Enter end date"
                      value={experience.endDate.value || ""}
                      onInput={(e) =>
                        handleWorkExpInputChange(
                          e,
                          experience.endDate.id,
                          index
                        )
                      }
                    />
                    {experience?.endDate?.value?.length === 0 &&
                    experience?.endDate?.invalid ? (
                      <p className="mb-0 ml-2 label is-size-5 has-text-danger">
                        Job end date is required.
                      </p>
                    ) : (
                      ""
                    )}
                    <label
                      className="is-flex is-justify-content-flex-start is-align-items-center"
                      style={{ gap: "10px", textAlign: "center" }}
                      key={experience.currentlyEmployed.id}
                    >
                      <input
                        type="checkbox"
                        className={`${
                          experience.currentlyEmployed.disable ? "disabled" : ""
                        }`}
                        id={experience.currentlyEmployed.id}
                        name={experience.currentlyEmployed.id}
                        defaultChecked={experience.currentlyEmployed.value}
                        checked={experience.currentlyEmployed.value}
                        onChange={(e) =>
                          handleCurrentlyEmployedChange(
                            e,
                            experience.currentlyEmployed.id,
                            index
                          )
                        }
                      />
                      <span className="is-size-5 mt-2">Currently Employed</span>
                    </label>
                    {/* <OmniCheckboxComponent
                      ref={employedRef}
                      id={experience.currentlyEmployed.id}
                      name={experience.currentlyEmployed.id}
                      checked={experience.currentlyEmployed.value}
                      change={(e) =>
                        handleCurrentlyEmployedChange(
                          e,
                          experience.currentlyEmployed.id,
                          index
                        )
                      }
                    >
                      <label> Currently employed</label>
                    </OmniCheckboxComponent> */}
                  </div>
                </div>
                {/* <div className="columns pl-3">
                  <div className="column column-gap p-0">
                    <p className="mb-0 ml-2 label is-size-5">
                      * Job description
                    </p>
                  </div>
                </div> */}
                {/* <div className="columns pl-5">
                  <div className="is-flex is-justify-content-center is-align-items-start pt-2">
                    <p className="has-text-grey">
                      I will be responsible for the
                    </p>
                  </div>
                  <div className="">
                    <input
                      type="text"
                      className={`input ml-2 mr-2 ${
                        experience.task1.invalid ? "error" : ""
                      }`}
                      placeholder="Task 1"
                      name={experience.task1.id}
                      style={{ width: "120px" }}
                      value={experience?.task1?.value || ""}
                      onChange={(e) =>
                        handleWorkExpInputChange(e, experience.task1.id, index)
                      }
                    />
                    ,
                    <div className="pl-1">
                      {experience?.task1?.value?.length === 0 &&
                      experience?.task1?.invalid ? (
                        <p className="mb-0 ml-2 label is-size-5 has-text-danger">
                          Task 1 required.
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="">
                    <input
                      type="text"
                      className={`input ml-2 mr-2 ${
                        experience.task2.invalid ? "error" : ""
                      }`}
                      placeholder="Task 2"
                      name={experience.task2.id}
                      style={{ width: "120px" }}
                      value={experience?.task2?.value || ""}
                      onChange={(e) =>
                        handleWorkExpInputChange(e, experience.task2.id, index)
                      }
                    />
                    ,
                    <div className="pl-1">
                      {experience?.task2?.value?.length === 0 &&
                      experience?.task2?.invalid ? (
                        <p className="mb-0 ml-2 label is-size-5 has-text-danger">
                          Task 2 required.
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="">
                    <input
                      type="text"
                      className={`input ml-2 mr-2 ${
                        experience.task3.invalid ? "error" : ""
                      }`}
                      placeholder="Task 3"
                      name={experience.task3.id}
                      style={{ width: "120px" }}
                      value={experience?.task3?.value || ""}
                      onChange={(e) =>
                        handleWorkExpInputChange(e, experience.task3.id, index)
                      }
                    />
                    <div className="pl-1">
                      {experience?.task3?.value?.length === 0 &&
                      experience?.task3?.invalid ? (
                        <p className="mb-0 ml-2 label is-size-5 has-text-danger">
                          Task 3 required.
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="is-flex is-justify-content-center is-align-items-start pt-2">
                    <p className="has-text-grey">
                      {" "}
                      of all projects for our client{" "}
                    </p>
                  </div>
                  <div className="">
                    <input
                      type="text"
                      className={`input ml-2 mr-2 ${
                        experience.client_dept.invalid ? "error" : ""
                      }`}
                      placeholder="Client/ Dept"
                      name={experience.client_dept.id}
                      style={{ width: "120px" }}
                      value={experience?.client_dept?.value || ""}
                      onChange={(e) =>
                        handleWorkExpInputChange(
                          e,
                          experience.client_dept.id,
                          index
                        )
                      }
                    />
                    <div className="pl-1">
                      {experience?.client_dept?.value?.length === 0 &&
                      experience?.client_dept?.invalid ? (
                        <p className="mb-0 ml-2 label is-size-5 has-text-danger">
                          Client/Dept is required.
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div> */}
                <div className="columns">
                  <div
                    className="column column-gap is-one-third"
                    key={experience?.city?.id}
                  >
                    <p className="mb-0 ml-2 label is-size-5">* City</p>
                    <input
                      type="text"
                      id={experience?.city?.id}
                      className={`input ${
                        experience?.city?.invalid ? "error" : ""
                      } ${experience?.city?.disable ? "disabled" : ""}`}
                      name={experience?.city?.id}
                      placeholder="Enter your city"
                      value={experience?.city?.value || ""}
                      onChange={(e) =>
                        handleWorkExpInputChange(
                          e,
                          experience?.city?.id,
                          index
                        )
                      }
                    />
                    {experience?.city?.value?.length === 0 &&
                    experience?.city?.invalid ? (
                      <p className="mb-0 ml-2 label is-size-5 has-text-danger">
                        City is required.
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className="column column-gap is-one-third"
                    key={experience?.state?.id}
                  >
                    <p className="mb-0 ml-2 label is-size-5">* State</p>
                    <input
                      type="text"
                      id={experience?.state?.id}
                      name={experience?.state?.id}
                      className={`input ${
                        experience?.state?.invalid ? "error" : ""
                      } ${experience?.state?.disable ? "disabled" : ""}`}
                      placeholder="Enter your state"
                      value={experience?.state?.value || ""}
                      onChange={(e) =>
                        handleWorkExpInputChange(
                          e,
                          experience?.state?.id,
                          index
                        )
                      }
                    />
                    {experience?.functionName?.value?.length === 0 &&
                    experience?.functionName?.invalid ? (
                      <p className="mb-0 ml-2 label is-size-5 has-text-danger">
                        State is required.
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className="column column-gap is-one-third"
                    key={experience?.country?.id}
                  >
                    <p className="mb-0 ml-2 label is-size-5">* Country</p>
                    <input
                      type="text"
                      id={experience?.country?.id}
                      className={`input ${
                        experience?.country?.invalid ? "error" : ""
                      } ${experience?.country?.disable ? "disabled" : ""}`}
                      name={experience?.country?.id}
                      placeholder="Enter your country"
                      value={experience?.country?.value || ""}
                      onChange={(e) =>
                        handleWorkExpInputChange(
                          e,
                          experience?.country?.id,
                          index
                        )
                      }
                    />
                    {experience?.country?.value?.length === 0 &&
                    experience?.country?.invalid ? (
                      <p className="mb-0 ml-2 label is-size-5 has-text-danger">
                        Country is required.
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="columns" key={experience.workSummary.id}>
                  <div className="column column-gap is-full mb-2">
                    <p className="mb-0 ml-2 label is-size-5">* Work summary</p>
                    <ReactQuill
                      id={experience.workSummary.id}
                      name={experience.workSummary.id}
                      theme="snow"
                      placeholder="Enter work summary"
                      value={experience.workSummary.value || ""}
                      onChange={(e) =>
                        handleWorkSummaryInutChange(
                          e,
                          experience.workSummary.id,
                          index
                        )
                      }
                    />
                  </div>
                </div>
                {/* <div className="is-flex is-flex-direction-column">
                  {experience.projects &&
                    experience.projects.map((project, indx) => {
                      return (
                        <div
                          id={`panel-${indx}`}
                          key={`panel-${indx}`}
                          className={`panel p-2 columns mt-4 ml-0 mr-0 mb-0 p-0 is-flex is-flex-direction-column ${
                            project.showProjDetail
                              ? "panel-expand"
                              : "panel-collapse"
                          }`}
                        >
                          <div className="is-flex is-align-items-center is-justify-content-space-between">
                            <div className="pl-4 label">
                              Project Detail {indx + 1}
                            </div>
                            <div className="is-flex is-align-items-center is-justify-content-space-between">
                              <OmniTooltipComponent>
                                <button
                                  slot="invoker"
                                  className="button no-border-background is-shadowless is-text"
                                  onClick={() => {
                                    deleteWorkproject(indx);
                                  }}
                                >
                                  <OmniIconComponent
                                    id={`ic-delete-${indx}`}
                                    className="is-size-4 is-clickable"
                                    icon-id="omni:interactive:delete"
                                  ></OmniIconComponent>
                                </button>
                              </OmniTooltipComponent>
                              <OmniTooltipComponent>
                                <button
                                  slot="invoker"
                                  className="button no-border-background is-shadowless is-text"
                                  onClick={(e) =>
                                    toggleProjectAccordian(e, indx, index)
                                  }
                                >
                                  <OmniIconComponent
                                    id={`ic-panel-${indx}`}
                                    className="is-size-2 is-clickable"
                                    icon-id={`omni:interactive:${
                                      project.showProjDetail ? "down" : "up"
                                    }`}
                                  ></OmniIconComponent>
                                </button>
                              </OmniTooltipComponent>
                            </div>
                          </div>
                          <div className="column pl-4 pb-5 pt-5">
                            <div
                              id={`panelContainer-${indx}`}
                              className="is-flex is-flex-direction-column pt-0 panelContainer"
                              style={{ gap: "15px" }}
                            >
                              <div className="columns">
                                <div
                                  className="column column-gap is-full"
                                  key={project.projectName.id}
                                >
                                  <p className="mb-0 ml-2 label is-size-5">
                                    * Project name
                                  </p>
                                  <input
                                    type="text"
                                    id={project.projectName.id}
                                    className={`input ${
                                      project.projectName.invalid ? "error" : ""
                                    }`}
                                    name={project.projectName.id}
                                    placeholder="Enter project name"
                                    value={project.projectName.value || ""}
                                    onChange={(e) =>
                                      handleProjDetInputChange(
                                        e,
                                        project.projectName.id,
                                        indx,
                                        index
                                      )
                                    }
                                  />
                                  <div className="pl-1">
                                    {project?.projectName?.value?.length ===
                                      0 && project?.projectName?.invalid ? (
                                      <p className="mb-0 ml-2 label is-size-5 has-text-danger">
                                        Project name is required.
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="columns">
                                <div
                                  className="column column-gap is-half"
                                  key={project.projDesignation.id}
                                >
                                  <p className="mb-0 ml-2 label is-size-5">
                                    * Designation name
                                  </p>
                                  <input
                                    type="text"
                                    id={project.projDesignation.id}
                                    className={`input ${
                                      project.projDesignation.invalid
                                        ? "error"
                                        : ""
                                    }`}
                                    name={project.projDesignation.id}
                                    placeholder="Enter desgination"
                                    value={project.projDesignation.value || ""}
                                    onChange={(e) =>
                                      handleProjDetInputChange(
                                        e,
                                        project.projDesignation.id,
                                        indx,
                                        index
                                      )
                                    }
                                  />
                                  <div className="pl-1">
                                    {project?.projDesignation?.value?.length ===
                                      0 && project?.projDesignation?.invalid ? (
                                      <p className="mb-0 ml-2 label is-size-5 has-text-danger">
                                        Designation is required.
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="column column-gap is-half"
                                  key={project.projFunctionName.id}
                                >
                                  <p className="mb-0 ml-2 label is-size-5">
                                    Function name
                                  </p>
                                  <input
                                    type="text"
                                    id={project.projFunctionName.id}
                                    className={`input ${
                                      project.projFunctionName.invalid
                                        ? "error"
                                        : ""
                                    }`}
                                    name={project.projFunctionName.id}
                                    placeholder="Enter function name"
                                    value={project.projFunctionName.value || ""}
                                    onChange={(e) =>
                                      handleProjDetInputChange(
                                        e,
                                        project.projFunctionName.id,
                                        indx,
                                        index
                                      )
                                    }
                                  />
                                  <div className="pl-1">
                                    {project?.projFunctionName?.value
                                      ?.length === 0 &&
                                    project?.projFunctionName?.invalid ? (
                                      <p className="mb-0 ml-2 label is-size-5 has-text-danger">
                                        Function name is required.
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div
                                className="columns"
                                key={project.projStartDate.id}
                              >
                                <div className="column column-gap is-half">
                                  <p className="mb-0 ml-2 label is-size-5">
                                    * Project start date
                                  </p>
                                  <input
                                    type="month"
                                    className={`input ${
                                      project.projStartDate.invalid
                                        ? "error"
                                        : ""
                                    }`}
                                    id={project.projStartDate.id}
                                    name={project.projStartDate.id}
                                    placeholder="Enter start date"
                                    value={project.projStartDate.value || ""}
                                    onInput={(e) =>
                                      handleProjDetInputChange(
                                        e,
                                        project.projStartDate.id,
                                        indx,
                                        index
                                      )
                                    }
                                  />
                                  <div className="pl-1">
                                    {project?.projStartDate?.value?.length ===
                                      0 && project?.projStartDate?.invalid ? (
                                      <p className="mb-0 ml-2 label is-size-5 has-text-danger">
                                        Project start date is required.
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="column column-gap is-half"
                                  key={project.projEndDate.id}
                                >
                                  <p className="mb-0 ml-2 label is-size-5">
                                    * Project end date
                                  </p>
                                  <input
                                    id={project.projEndDate.id}
                                    name={project.projEndDate.id}
                                    type="month"
                                    className={`input ${
                                      project.projEndDate.invalid ? "error" : ""
                                    }`}
                                    placeholder="Enter end date"
                                    value={project.projEndDate.value || ""}
                                    onInput={(e) =>
                                      handleProjDetInputChange(
                                        e,
                                        project.projEndDate.id,
                                        indx,
                                        index
                                      )
                                    }
                                  />
                                  <div className="pl-1">
                                    {project?.projEndDate?.value?.length ===
                                      0 && project?.projEndDate?.invalid ? (
                                      <p className="mb-0 ml-2 label is-size-5 has-text-danger">
                                        Project end date is required.
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="columns">
                                <div
                                  className="column column-gap is-full mb-2"
                                  key={project.projSummary.id}
                                >
                                  <p className="mb-0 ml-2 label is-size-5">
                                    * Project roles and responsibilities
                                  </p>
                                  <ReactQuill
                                    id={project.projSummary.id}
                                    name={project.projSummary.id}
                                    theme="snow"
                                    placeholder="Enter project summary"
                                    value={project.projSummary.value || ""}
                                    onChange={(e) =>
                                      handleProjectInputChange(
                                        e,
                                        project.projSummary.id,
                                        indx,
                                        index
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  <div className="columns is-flex is-justify-content-flex-start pr-3 ml-0 mt-6">
                    <OmniTooltipComponent>
                      <button
                        id="btnCreateReport"
                        slot="invoker"
                        className="is-flex button is-outlined is-flex-shrink-1 mr-3 is-link"
                        onClick={() => handleWorkproject(index)}
                      >
                        <OmniIconComponent
                          id="addIcon"
                          class="is-size-2 is-clickable is-normal mr-2"
                          icon-id="omni:interactive:add"
                        ></OmniIconComponent>
                        Add project
                      </button>
                    </OmniTooltipComponent>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        ))}

      <div className="columns is-flex is-justify-content-flex-start pr-3 ml-0 mt-6">
        <OmniTooltipComponent>
          <button
            id="btnCreateReport"
            slot="invoker"
            className="is-flex button is-outlined is-flex-shrink-1 mr-3 is-link"
            onClick={handleWorkExperience}
          >
            <OmniIconComponent
              id="addIcon"
              class="is-size-2 is-clickable is-normal mr-2"
              icon-id="omni:interactive:add"
            ></OmniIconComponent>
            Add work experience
          </button>
          {/* <div slot="content">
            <p>Add work experience</p>
          </div> */}
        </OmniTooltipComponent>
      </div>
      <div className="columns is-flex is-justify-content-flex-start pr-3 mt-4">
        <p className="mb-0 ml-2 label is-size-5">* Required fields</p>
      </div>
    </div>
  );
};
