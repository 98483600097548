import { useEffect, useState } from "react";
import "../styles/BasicInfo.css";
import "../styles/App.css";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { OmniTooltipComponent, OmniIconComponent } from "./omni-components";
export const EducationExperienceComponent = ({ getEducationExp }) => {
  const [experiences, setExperiences] = useState([
    {
      schoolName: { id: "schoolName", value: "" },
      universityName: { id: "universityName", value: "" },
      startDate: { id: "startDate", value: "" },
      endDate: { id: "endDate", value: "" },
      currentlyStudying: { id: "currentlyStudying", value: false },
      degree: { id: "degree", value: "" },
      fieldOfStudy: { id: "fieldOfStudy", value: "" },
      educationSummary: { id: "educationSummary", value: "" },
      // certificationName: { id: "certificationName", value: "" },
      // issuingInst: { id: "issuingInst", value: "" },
      // issueDate: { id: "issueDate", value: "" },
      show: true,
    },
  ]);

  useEffect(() => {
    if (window.sessionStorage.getItem("educationExp")) {
      setExperiences(JSON.parse(window.sessionStorage.getItem("educationExp")));
    }
  }, []);

  const handleWorkExpInputChange = (e, key, index) => {
    const { name, value } = e.target;
    const nextCounters = experiences.map((c, i) => {
      if (i === index) {
        c[`${key}`]["value"] = value;
      }
      return c;
    });
    setExperiences(nextCounters);
    getEducationExp(experiences);
  };

  const handleEduSummaryInutChange = (e, key, index) => {
    const nextCounters = experiences.map((c, i) => {
      if (i === index) {
        c[`${key}`]["value"] = e;
      }
      return c;
    });
    setExperiences(nextCounters);
    getEducationExp(experiences);
  };

  const toggleAccordian = (e, index) => {
    const isShow = experiences[index]?.show ? false : true;
    let newArr = [...experiences];
    newArr[index].show = isShow;
    setExperiences(newArr);
  };

  const handleWorkExperience = () => {
    const newArr = [...experiences];
    newArr.push({
      schoolName: { id: "schoolName", value: "" },
      universityName: { id: "universityName", value: "" },
      startDate: { id: "startDate", value: "" },
      endDate: { id: "endDate", value: "" },
      currentlyStudying: { id: "currentlyStudying", value: false },
      degree: { id: "degree", value: "" },
      fieldOfStudy: { id: "fieldOfStudy", value: "" },
      educationSummary: { id: "educationSummary", value: "" },
      show: true,
    });
    setExperiences(newArr);
  };

  const deleteWorkExperience = (index) => {
    setExperiences([
      ...experiences.slice(0, index),
      ...experiences.slice(index + 1, experiences.length),
    ]);
  };

  return (
    <div className="is-flex is-flex-direction-column">
      {experiences &&
        experiences.map((experience, index) => (
          <div
            id={`panel-${index}`}
            key={`panel-${index}`}
            className={`panel p-2 columns mt-4 ml-0 mr-0 mb-0 p-0 is-flex is-flex-direction-column ${
              experience.show ? "panel-expand" : "panel-collapse"
            }`}
          >
            <div className="is-flex is-align-items-center is-justify-content-space-between">
              <div className="pl-4 label">
                Educational Experience {index + 1}
              </div>
              <div className="is-flex is-align-items-center is-justify-content-space-between">
                <OmniTooltipComponent>
                  <button
                    slot="invoker"
                    className="button no-border-background is-shadowless is-text"
                    onClick={() => {
                      deleteWorkExperience(index);
                    }}
                  >
                    <OmniIconComponent
                      id={`ic-delete-${index}`}
                      className="is-size-4 is-clickable"
                      icon-id="omni:interactive:delete"
                    ></OmniIconComponent>
                  </button>
                </OmniTooltipComponent>
                <OmniTooltipComponent>
                  <button
                    slot="invoker"
                    className="button no-border-background is-shadowless is-text"
                    onClick={(e) => toggleAccordian(e, index)}
                  >
                    <OmniIconComponent
                      id={`ic-panel-${index}`}
                      className="is-size-2 is-clickable"
                      icon-id={`omni:interactive:${
                        experience.show ? "down" : "up"
                      }`}
                    ></OmniIconComponent>
                  </button>
                </OmniTooltipComponent>
              </div>
            </div>
            <div className="column pl-4 pb-5 pt-5">
              <div
                id={`panelContainer-${index}`}
                className="is-flex is-flex-direction-column pt-0 panelContainer"
                style={{ gap: "15px" }}
              >
                <div className="columns">
                  <div
                    className="column column-gap is-half"
                    key={experience.schoolName.id}
                  >
                    <p className="mb-0 ml-2 label is-size-5">
                      * School/ College name
                    </p>
                    <input
                      type="text"
                      id={experience.schoolName.id}
                      className="input"
                      name={experience.schoolName.id}
                      placeholder="Enter school/ college name"
                      value={experience.schoolName.value || ""}
                      onChange={(e) =>
                        handleWorkExpInputChange(
                          e,
                          experience.schoolName.id,
                          index
                        )
                      }
                    />
                  </div>
                  <div
                    className="column column-gap is-half"
                    key={experience.universityName.id}
                  >
                    <p className="mb-0 ml-2 label is-size-5">
                      * University name
                    </p>
                    <input
                      type="text"
                      id={experience.universityName.id}
                      name={experience.universityName.id}
                      className="input"
                      placeholder="Enter university name"
                      value={experience.universityName.value || ""}
                      onChange={(e) =>
                        handleWorkExpInputChange(
                          e,
                          experience.universityName.id,
                          index
                        )
                      }
                    />
                  </div>
                </div>
                <div className="columns" key={experience.startDate.id}>
                  <div className="column column-gap is-half">
                    <p className="mb-0 ml-2 label is-size-5">
                      * School/ College start date
                    </p>
                    <input
                      type="month"
                      className="input"
                      id={experience.startDate.id}
                      name={experience.startDate.id}
                      placeholder="Enter start date"
                      value={experience.startDate.value || ""}
                      onInput={(e) =>
                        handleWorkExpInputChange(
                          e,
                          experience.startDate.id,
                          index
                        )
                      }
                    />
                  </div>
                  <div
                    className="column column-gap is-half"
                    key={experience.endDate.id}
                  >
                    <p className="mb-0 ml-2 label is-size-5">
                      * School/ College end date
                    </p>
                    <input
                      id={experience.endDate.id}
                      name={experience.endDate.id}
                      type="month"
                      className="input"
                      placeholder="Enter end date"
                      value={experience.endDate.value || ""}
                      onInput={(e) =>
                        handleWorkExpInputChange(
                          e,
                          experience.endDate.id,
                          index
                        )
                      }
                    />
                    {/* <label
                      className="is-flex is-justify-content-flex-start is-align-items-center"
                      style={{ gap: "10px", textAlign: "center" }}
                      key={experience.isCurrentlyEmployed.id}
                    >
                      <input
                        type="checkbox"
                        id={experience.currentlyEmployed.id}
                        name={experience.currentlyEmployed.id}
                        checked={experience.currentlyEmployed.value}
                        value={experience.currentlyEmployed.value || ""}
                        onChange={(e) =>
                          handleWorkExpInputChange(
                            e,
                            experience.currentlyEmployed.id,
                            index
                          )
                        }
                      />
                      <span>Currently Employed</span>
                    </label> */}
                  </div>
                </div>
                <div className="columns">
                  <div
                    className="column column-gap is-half"
                    key={experience.degree.id}
                  >
                    <p className="mb-0 ml-2 label is-size-5">* Degree</p>
                    <input
                      type="text"
                      id={experience.degree.id}
                      className="input"
                      name={experience.degree.id}
                      placeholder="Enter degree"
                      value={experience.degree.value || ""}
                      onChange={(e) =>
                        handleWorkExpInputChange(e, experience.degree.id, index)
                      }
                    />
                  </div>
                  <div
                    className="column column-gap is-half"
                    key={experience.fieldOfStudy.id}
                  >
                    <p className="mb-0 ml-2 label is-size-5">
                      * Field of Study
                    </p>
                    <input
                      type="text"
                      id={experience.fieldOfStudy.id}
                      name={experience.fieldOfStudy.id}
                      className="input"
                      placeholder="Enter field of study"
                      value={experience.fieldOfStudy.value || ""}
                      onChange={(e) =>
                        handleWorkExpInputChange(
                          e,
                          experience.fieldOfStudy.id,
                          index
                        )
                      }
                    />
                  </div>
                </div>
                {/* <div className="columns">
                  <div
                    className="column column-gap is-full"
                    key={experience.degree.id}
                  >
                    <p className="mb-0 ml-2 label is-size-5">
                      Certification name
                    </p>
                    <input
                      type="text"
                      id={experience.certificationName.id}
                      className="input"
                      name={experience.certificationName.id}
                      placeholder="Enter certification name"
                      value={experience.certificationName.value || ""}
                      onChange={(e) =>
                        handleWorkExpInputChange(
                          e,
                          experience.certificationName.id,
                          index
                        )
                      }
                    />
                  </div>
                </div> */}
                {/* <div className="columns">
                  <div
                    className="column column-gap is-half"
                    key={experience.issuingInst.id}
                  >
                    <p className="mb-0 ml-2 label is-size-5">* Issued from</p>
                    <input
                      type="text"
                      id={experience.issuingInst.id}
                      name={experience.issuingInst.id}
                      className="input"
                      placeholder="Issued from (institution name)"
                      value={experience.issuingInst.value || ""}
                      onChange={(e) =>
                        handleWorkExpInputChange(
                          e,
                          experience.issuingInst.id,
                          index
                        )
                      }
                    />
                  </div>
                  <div
                    className="column column-gap is-half"
                    key={experience.issueDate.id}
                  >
                    <p className="mb-0 ml-2 label is-size-5">Issue date</p>
                    <input
                      type="date"
                      id={experience.issueDate.id}
                      className="input"
                      name={experience.issueDate.id}
                      placeholder="Enter issued date"
                      value={experience.issueDate.value || ""}
                      onChange={(e) =>
                        handleWorkExpInputChange(
                          e,
                          experience.issueDate.id,
                          index
                        )
                      }
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        ))}

      <div className="columns is-flex is-justify-content-flex-start pr-3 ml-0 mt-6">
        <OmniTooltipComponent>
          <button
            id="btnCreateReport"
            slot="invoker"
            className="is-flex button is-outlined is-flex-shrink-1 mr-3 is-link"
            onClick={handleWorkExperience}
          >
            <OmniIconComponent
              id="addIcon"
              class="is-size-2 is-clickable is-normal mr-2"
              icon-id="omni:interactive:add"
            ></OmniIconComponent>
            Add educational experience
          </button>
          {/* <div slot="content">
            <p>Add work experience</p>
          </div> */}
        </OmniTooltipComponent>
      </div>
      <div className="columns is-flex is-justify-content-flex-start pr-3 mt-4">
        <p className="mb-0 ml-2 label is-size-5">* Required fields</p>
      </div>
    </div>
  );
};
