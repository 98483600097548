import { useEffect, useState } from "react";
import "../styles/BasicInfo.css";
import cities from "cities.json";
import ReactQuill from "react-quill";
import Indian_states_cities_list from "indian-states-cities-list";
import { subFunctions } from '../constants';
import { OmniDropdown } from "./omni-components";
export const PersonalInfoComponent = ({ getPersonalInfo }) => {
  const [allCity, setAllCity] = useState([]);
  const [personalInfo, setPersonalInfo] = useState({
    designation: {
      id: "designation",
      value: null,
      required: true,
      invalid: false,
    },
    fName: {
      id: "fName",
      value: null,
      required: true,
      invalid: false,
    },
    lName: {
      id: "lName",
      value: null,
      required: true,
      invalid: false,
    },
    functionName: {
      id: "functionName",
      value: null,
      required: true,
      invalid: false,
    },
    subFunctionName: {
      id: "subFunctionName",
      value: null,
      required: true,
      invalid: false,
    },
    phone: {
      id: "phone",
      value: null,
      required: true,
      invalid: false,
    },
    email: {
      id: "email",
      value: null,
      required: true,
      invalid: false,
    },
    address: {
      id: "address",
      value: null,
      required: false,
      invalid: false,
    },
    city: {
      id: "city",
      value: null,
      required: true,
      invalid: false,
    },
    state: {
      id: "state",
      value: null,
      required: false,
      invalid: false,
    },
    country: {
      id: "country",
      value: null,
      required: false,
      invalid: false,
    },
    zip: {
      id: "zip",
      value: null,
      required: false,
      invalid: false,
    },
    aboutMe: {
      id: "aboutMe",
      value: "",
      invalid: false,
      disable: false,
    },
    domainName: {
      id: "domainName",
      value: null,
      required: true,
      invalid: false,
    },
    yearsOfExp: {
      id: "yearsOfExp",
      value: null,
      required: true,
      invalid: false,
    },
    skill1: {
      id: "skill1",
      value: null,
      required: true,
      invalid: false,
    },
    skill2: {
      id: "skill2",
      value: null,
      required: true,
      invalid: false,
    },
    skill3: {
      id: "skill3",
      value: null,
      required: true,
      invalid: false,
    },
    portfolioLink: {
      id: "portfolioLink",
      value: null,
      required: false,
      invalid: false,
    },
  });

  const functionList = [{
    id: 1,
    label: "Business Support Services",
    value: "Business Support Services",
  },{
    id: 2,
    label: "Creative Services",
    value: "Creative Services",
  },{
    id: 3,
    label: "Enablement",
    value: "Enablement",
  },{
    id: 4,
    label: "Flywheel",
    value: "Flywheel",
  },{
    id: 5,
    label: "Market Research",
    value: "Market Research",
  },{
    id: 6,
    label: "Marketing Science",
    value: "Marketing Science",
  },{
    id: 7,
    label: "Media Services",
    value: "Media Services",
  },{
    id: 8,
    label: "Omnicom IT",
    value: "Omnicom IT",
  },{
    id: 9,
    label: "Project Management",
    value: "Project Management",
  },{
    id: 10,
    label: "Technology",
    value: "Technology",
  }]

  const sortBy = (field) => {
    return function (a, b) {
      return (a[field] > b[field]) - (a[field] < b[field]);
    };
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("personalInfo")) {
      setPersonalInfo(
        JSON.parse(window.sessionStorage.getItem("personalInfo"))
      );
    }
    let arr = [];
    Object.values(Indian_states_cities_list.STATE_WISE_CITIES).map((cities) => {
      cities.map((city) => {
        if (city.value) {
          const idx = arr.findIndex((dt) => dt.value === city.value);

          if (idx === -1) {
            arr.push({
              label: city.label,
              value: city.value,
            });
          }
        }
      });
    });
    arr.sort(sortBy("label"));
    setAllCity(arr);
  }, []);

  const handlePersonalInfoChange = (e, key) => {
    const { value } = e.target;
    const counter = { ...personalInfo };
    counter[`${key}`]["value"] = value;
    if (value.length === 0) {
      counter[`${key}`]["invalid"] = true;
    } else {
      counter[`${key}`]["invalid"] = false;
    }
    setPersonalInfo(counter);
    getPersonalInfo(personalInfo);
  };

  const handlePersonalInfoPhoneChange = (e, key) => {
    const { value } = e.target;
    // const re = /^[0-9\b]+$/;
    const counter = { ...personalInfo };
    counter[`${key}`]["value"] = value
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*?)\..*/g, "$1");
    if (value.length === 0) {
      counter[`${key}`]["invalid"] = true;
    } else {
      counter[`${key}`]["invalid"] = false;
    }
    setPersonalInfo(counter);
    getPersonalInfo(personalInfo);
  };

  const handleAboutmeChange = (e, key) => {
    const counter = { ...personalInfo };
    counter[`${key}`]["value"] = e
    setPersonalInfo(counter);
    getPersonalInfo(personalInfo);
  }

  const handleSubmitSelfie = () => {
    const imgPath = document.querySelector('input[type=file]').files[0];
    const reader = new FileReader();
    reader.addEventListener("load", function() {
      window.sessionStorage.setItem("selfie", reader.result);
    }, false)
    if (imgPath) {
      reader.readAsDataURL(imgPath);
    }
  }

  return (
    <div className="is-flex is-flex-direction-column">
      <div className="columns">
        <div className="column column-gap">
          <p className="mb-0 ml-2 label is-size-5">* First name</p>
          <input
            type="text"
            className={`input ${personalInfo?.fName?.invalid ? "error" : ""}`}
            placeholder="Enter first name"
            name={personalInfo.fName?.id}
            value={personalInfo?.fName?.value || ""}
            onChange={(e) =>
              handlePersonalInfoChange(e, personalInfo?.fName?.id)
            }
          />
          {personalInfo?.fName?.value?.length === 0 &&
          personalInfo?.fName?.invalid ? (
            <p className="mb-0 ml-2 label is-size-5 has-text-danger">
              First name is required.
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="column column-gap">
          <p className="mb-0 ml-2 label is-size-5">* Last name</p>
          <input
            type="text"
            className={`input ${personalInfo.lName.invalid ? "error" : ""}`}
            name={personalInfo.lName.id}
            placeholder="Enter last name"
            value={personalInfo?.lName?.value || ""}
            onChange={(e) =>
              handlePersonalInfoChange(e, personalInfo?.lName?.id)
            }
          />
          {personalInfo?.lName?.value?.length === 0 &&
          personalInfo?.lName?.invalid ? (
            <p className="mb-0 ml-2 label is-size-5 has-text-danger">
              Last name is required.
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="columns">
        <div className="column column-gap">
          <p className="mb-0 ml-2 label is-size-5">* OGS Designation</p>
          <input
            type="text"
            className={`input ${
              personalInfo?.designation?.invalid ? "error" : ""
            }`}
            placeholder="Enter designation"
            name={personalInfo?.designation?.id}
            value={personalInfo?.designation?.value || ""}
            onChange={(e) =>
              handlePersonalInfoChange(e, personalInfo.designation.id)
            }
          />
          {personalInfo?.designation?.value?.length === 0 &&
          personalInfo?.designation?.invalid ? (
            <p className="mb-0 ml-2 label is-size-5 has-text-danger">
              Designation is required.
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="column column-gap">
          <p className="mb-0 ml-2 label is-size-5">* Function Name</p>
          {/* <input
            type="text"
            className={`input ${
              personalInfo.functionName.invalid ? "error" : ""
            }`}
            name={personalInfo.functionName.id}
            placeholder="Enter function name"
            value={personalInfo?.functionName?.value || ""}
            onChange={(e) =>
              handlePersonalInfoChange(e, personalInfo.functionName.id)
            }
          /> */}
          <OmniDropdown
            style={{ width: "100%", fontSize: "14px" }}
            typeahead
            searchindropdown
            options={functionList}
            id={personalInfo?.functionName?.id}
            value={personalInfo?.functionName?.value}
            fieldid="label"
            fieldname="value"
            placeholder="Select function"
            onDropdownChange={(e) =>
                handlePersonalInfoChange(e, personalInfo.functionName.id)
            }
          ></OmniDropdown>
          {personalInfo?.functionName?.value && personalInfo?.functionName?.value[0]?.value?.length === 0 &&
            personalInfo?.functionName?.invalid ? (
            <p className="mb-0 ml-2 label is-size-5 has-text-danger">
              Function name is required.
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="column column-gap">
          <p className="mb-0 ml-2 label is-size-5">* Sub Function</p>
          <OmniDropdown
            style={{ width: "100%", fontSize: "14px" }}
            typeahead
            searchindropdown
            options={subFunctions}
            id={personalInfo?.subFunctionName?.id}
            value={personalInfo?.subFunctionName?.value}
            fieldid="label"
            fieldname="value"
            placeholder="Select sub function"
            onDropdownChange={(e) =>
              handlePersonalInfoChange(e, personalInfo.subFunctionName.id)
          }
          ></OmniDropdown>
          
        </div>
      </div>
      <div className="columns">
        <div className="column column-gap">
          <p className="mb-0 ml-2 label is-size-5">* Official Email address</p>
          <input
            type="text"
            className={`input ${personalInfo.email.invalid ? "error" : ""}`}
            name={personalInfo.email.id}
            placeholder="Enter email address"
            value={personalInfo?.email?.value || ""}
            onChange={(e) => handlePersonalInfoChange(e, personalInfo.email.id)}
          />
          {personalInfo?.email?.value?.length === 0 &&
          personalInfo?.email?.invalid ? (
            <p className="mb-0 ml-2 label is-size-5 has-text-danger">
              Email is required.
            </p>
          ) : (
            ""
          )}
          {/* <p className="mb-0 ml-2 label is-size-5">Address</p>
          <textarea
            type="text"
            rows="2"
            className="input"
            name={personalInfo.address.id}
            placeholder="Enter address"
            value={personalInfo?.address?.value || ""}
            onChange={(e) =>
              handlePersonalInfoChange(e, personalInfo.address.id)
            }
          /> */}
        </div>
        <div className="column column-gap">
          <p className="mb-0 ml-2 label is-size-5">* Contact</p>
          <input
            type="text"
            className={`input ${personalInfo.phone.invalid ? "error" : ""}`}
            name={personalInfo.phone.id}
            placeholder="Enter phone number"
            value={personalInfo?.phone?.value || ""}
            maxLength="10"
            onInput={(e) => {
              handlePersonalInfoPhoneChange(e, personalInfo.phone.id);
            }}
          />
          {personalInfo?.phone?.value?.length === 0 &&
          personalInfo?.phone?.invalid ? (
            <p className="mb-0 ml-2 label is-size-5 has-text-danger">
              Phone number is required.
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="columns">
        {/* <div className="column column-gap">
          <p className="mb-0 ml-2 label is-size-5">* City</p>
          <input
            type="text"
            className={`input ${personalInfo.city.invalid ? "error" : ""}`}
            name={personalInfo.city.id}
            placeholder="Enter city name"
            value={personalInfo?.city?.value || ""}
            onChange={(e) => handlePersonalInfoChange(e, personalInfo.city.id)}
          />
          {personalInfo?.city?.value?.length === 0 &&
          personalInfo?.city?.invalid ? (
            <p className="mb-0 ml-2 label is-size-5 has-text-danger">
              City name is required.
            </p>
          ) : (
            ""
          )}
        </div> */}
        {/* <div className="column column-gap">
          <p className="mb-0 ml-2 label is-size-5">State</p>
          <input
            type="text"
            className="input"
            name={personalInfo.state.id}
            placeholder="Enter state name"
            value={personalInfo?.state?.value || ""}
            onChange={(e) => handlePersonalInfoChange(e, personalInfo.state.id)}
          />
        </div> */}
      </div>
      {/* <div className="columns">
        <div className="column column-gap">
          <p className="mb-0 ml-2 label is-size-5">Country</p>
          <input
            type="text"
            className="input"
            name={personalInfo.country.id}
            placeholder="Enter country name"
            value={personalInfo?.country?.value || ""}
            onChange={(e) =>
              handlePersonalInfoChange(e, personalInfo.country.id)
            }
          />
        </div>
        <div className="column column-gap">
          <p className="mb-0 ml-2 label is-size-5">Zip code</p>
          <input
            type="text"
            className="input"
            name={personalInfo.zip.id}
            placeholder="Enter zip code"
            value={personalInfo?.zip?.value || ""}
            onChange={(e) => handlePersonalInfoChange(e, personalInfo.zip.id)}
          />
        </div>
      </div> */}
      <div className="columns">
        <div className="column column-gap">
          <p className="mb-0 ml-2 label is-size-5">* Base Location</p>
          {/* <OmniDropdownNew2
            style={{ width: "100%", fontSize: "14px" }}
            typeahead
            searchindropdown
            options={allCity}
            id={personalInfo.city.id}
            fieldid="label"
            fieldname="value"
            placeholder="Select city"
            onDropdownChange={(event) => {
              console.log(event);
              // personalInfo.city.value = event.target.value[0]?.label.trim();
              const counter = { ...personalInfo };
              counter[`${personalInfo.city.id}`]["value"] =
                event.target.value[0]?.label.trim();
            }}
          ></OmniDropdownNew2> */}
          <input
            type="text"
            className={`input ${personalInfo.city.invalid ? "error" : ""}`}
            name={personalInfo.city.id}
            placeholder="Enter city name"
            value={personalInfo?.city?.value || ""}
            onChange={(e) => handlePersonalInfoChange(e, personalInfo.city.id)}
          />
          {personalInfo?.city?.value?.length === 0 &&
          personalInfo?.city?.invalid ? (
            <p className="mb-0 ml-2 label is-size-5 has-text-danger">
              City name is required.
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="column column-gap">
          <p className="mb-0 ml-2 label is-size-5">
            Enter portfolio link (Only for Creative services)
          </p>
          <input
            type="text"
            className="input"
            name={personalInfo.portfolioLink.id}
            placeholder="Enter portfolio link"
            value={personalInfo?.portfolioLink?.value || ""}
            onChange={(e) =>
              handlePersonalInfoChange(e, personalInfo.portfolioLink.id)
            }
          />
        </div>
      </div>
      <div className="columns">
        <div className="column column-gap">
          <p className="mb-0 ml-2 label is-size-5">* About me</p>
        </div>
      </div>
      <div className="columns pl-4">
        <div className="column column-gap is-full mb-2">
          <ReactQuill
            id={personalInfo.aboutMe.id}
            name={personalInfo.aboutMe.id}
            theme="snow"
            placeholder="Briefly expain about yourself"
            value={personalInfo.aboutMe.value || ""}
            onChange={(e) =>
              handleAboutmeChange(
                e,
                personalInfo.aboutMe.id,
              )
            }
          />
        </div>
      </div>
      <div className="columns is-flex is-justify-content-flex-start pr-3 pt-5">
        <div className="file">
          <label className="file-label">
            <input className="file-input" type="file" accept="image/*" name="selfie" onChange={handleSubmitSelfie} />
            <span className="file-cta">
              <span className="file-icon">
                <i className="fas fa-upload"></i>
              </span>
              <span className="file-label"> Upload your selfie </span>
            </span>
          </label>
        </div>
      </div>
      <div className="columns is-flex is-justify-content-flex-start pr-3">
        <p className="mb-0 mt-6 ml-2 label is-size-5">* Required fields</p>
      </div>
    </div>
  );
};
