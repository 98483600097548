import { createComponent } from "@lit-labs/react";
import React from "react";
import {
  OmniSearchElement,
  OmniTableElement,
  OmniTileElement,
  OmniStyleElement,
  OmniToolbarElement,
  OmniIconElement,
  OmniLoadingIndicatorElement,
  OmniTooltipElement,
  OmniSwitchElement,
  OmniCheckbox,
} from "omni-ui";
import { Om2Dropdown, Om2IconMenu } from "omni-campaign-ui";
import { OmniDropdown as OmniDropdownNew } from "omni-ui";

export const OmniDropdownNew2 = createComponent(
  React,
  "omni-dropdown",
  OmniDropdownNew,
  {
    onDropdownChange: "change",
  }
);

export const OmniSearchComponent = createComponent(
  React,
  "omni-search",
  OmniSearchElement,
  {
    onSearchUpdate: "search-update",
  }
);

export const OmniTableComponent = createComponent(
  React,
  "omni-table",
  OmniTableElement,
  {
    onSortUpdate: "sort",
    onExpandOrCollapse: "expand-or-collapse-rows",
  }
);

export const OmniTileComponent = createComponent(
  React,
  "omni-tile",
  OmniTileElement
);

export const OmniStyleComponent = createComponent(
  React,
  "omni-style",
  OmniStyleElement
);

export const OmniToolbarComponent = createComponent(
  React,
  "omni-toolbar",
  OmniToolbarElement
);

export const OmniIconComponent = createComponent(
  React,
  "omni-icon",
  OmniIconElement,
  {
    click: "click",
  }
);

export const OmniLoadingIndicatorComponent = createComponent(
  React,
  "omni-loading-indicator",
  OmniLoadingIndicatorElement
);

export const OmniDropdown = createComponent(
  React,
  "om2-dropdown",
  Om2Dropdown,
  {
    onDropdownChange: "change",
  }
);

export const OmniIconMenu = createComponent(
  React,
  "om2-icon-menu",
  Om2IconMenu,
  {
    onDropdownChange: "change",
  }
);

export const OmniTooltipComponent = createComponent(
  React,
  "omni-tooltip",
  OmniTooltipElement
);
export const OmniSwitchComponent = createComponent(
  React,
  "omni-switch",
  OmniSwitchElement,
  {
    onSwitchChange: "change",
    checked: "checked",
  }
);

export const OmniCheckboxComponent = createComponent(
  React,
  "omni-checkbox",
  OmniCheckbox,
  {
    change: "change",
    checked: "checked",
  }
);
