import { useRef } from "react";
import "../styles/Modal.css";
import { OmniIconComponent } from "./omni-components";
export const ModalComponent = (props) => {
  const modalRef = useRef(null);

  const handleModalOpen = () => {
    modalRef.current.setAttribute("style", "display:none");
  };
  return (
    <>
      <div className="modal is-active" ref={modalRef}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <section className="modal-card-body">
            <div className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-start">
              <div
                className="has-text-white is-size-4 is-flex mt-5 is-align-items-center"
                style={{ gap: "10px" }}
              >
                <div>
                  <OmniIconComponent
                    id="ic-info"
                    className="is-size-1 is-clickable fill-white"
                    icon-id="omni:informative:info"
                  ></OmniIconComponent>
                </div>
                <p className="has-text-white">{props?.message}</p>
              </div>

              <div className="">
                <OmniIconComponent
                  id="ic-remove"
                  className="is-size-1 is-clickable fill-white"
                  icon-id="omni:interactive:close"
                  onClick={handleModalOpen}
                ></OmniIconComponent>
              </div>
            </div>
            {/* <div className="buttons are-medium is-right mt-6 mb-1">
              <button className="button is-size-5 is-text has-text-white">
                Cancel
              </button>
            </div> */}
          </section>
        </div>
      </div>
    </>
  );
};
